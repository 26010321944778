import Badge from '@material-ui/core/Badge'
import { img300, unavailable } from '../../config/config'
import ContentModal from '../ContentModal/ContentModal'
import './SingleContent.css'

const SingleContent = ({ id, poster, title, date, media_type, vote_average }) => {
  return (
    <ContentModal media_type={media_type} id={id}>
      <Badge badgeContent={vote_average} color={vote_average > 6 ? 'primary' : 'secondary'} />
      <img className='poster' src={poster ? `${img300}/${poster}` : unavailable} alt={title} />
      <b className='title'>{title}</b>
      <span className='subTitle'>
        {media_type === 'tv' ? 'Serie' : 'Film'}
        <span className='subTitle'>{date}</span>
      </span>
    </ContentModal>
  )
}

export default SingleContent
